// Animations
.bounce-in.ng-animate {
  animation: none 0s;
}

.bounce-in {
  animation: bounce-in .9s 0s cubic-bezier(0.175, 0.885, 0.320, 1.275) forwards;
}

@keyframes bounce-in {
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(2px);
  }
}

@keyframes bounce-button-in {
  0% {
    transform: translateZ(0) scale(0.3);
  }
  100% {
    transform: translateZ(0) scale(1);
  }
}



.app {
  &-sidebar {
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: black;

    a {
      outline: none;
    }

    button {
      width: $app-sidebar-width - 42px;
      height: $app-sidebar-width - 42px;
      margin: 48px 0;
      background-color: black;
      outline: none;
      border: 2px solid;
      border-radius: 50px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      opacity: .5;

      &:focus {
        animation: bounce-button-in .45s 0s cubic-bezier(0.175, 0.885, 0.320, 1.275) forwards;
      }

      svg {
        height: 70%;
        width: 70%;
      }

    }
  }
}