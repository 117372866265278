.input-field--text {
  position: relative;
  width: 250px;
  height: 44px;
  margin-top: 30px;

  line-height: 1;


  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: #d3d3d3;
    transition: 0.2s all;
    cursor: text;
  }
  input {
    width: 100%;
    border: 0;
    outline: 0;
    padding: 0.5rem 0;
    border-bottom: 2px solid #d3d3d3;
    box-shadow: none;
    color: $text-gray-100;
    background-color: transparent;

    &:invalid {
      outline: 0;
      // color: #ff2300;
      //   border-color: #ff2300;
    }

    &:focus,
    &:valid {
      border-color: #00dd22;
    }

    &:focus ~ label,
    &:valid ~ label {
      font-size: 14px;
      top: -24px;
      color: #00dd22;
    }
  }
}

