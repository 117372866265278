@import "./Reset";
@import "./ThemeVars.scss";
@import "./ThemeColors";

@import "./Buttons";
@import "./Icons";
@import "./Form/Input";
@import "AppSidebar";
@import "../Application/Pages/Calendar/DayView";
@import "../Application/Pages/Task/Task";


h1, h2, h3, h4 {
  font-weight: 100;
}

.w-100 {
  width: 100% !important;
}

body {
  color: #fff;
  margin: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 100;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #00000092;
}

#root {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  align-items: center;
  flex-direction: row;
}

.app {
  &-container {
    width: 100%;
    max-width: $app-container-max-width;
    margin: 0 auto;
    height: 100%;
    max-height: $app-container-max-height;
    flex-direction: row;
    position: relative;
    background-color: black;

  }

  &-sidebar {
    position: absolute;
    left: 0;
    width: $app-sidebar-width;
  }

  &-content {
    flex-grow: 1;
    margin-top: $base-margin * 2;
    margin-right: $base-margin * 2;
    margin-bottom: $base-margin * 2;
    padding: $base-padding * 2;
    background-color: $app-bg-color;
    border-radius: 12px;
    margin-left: $app-sidebar-width;
  }

}
