
.timeline {
  position: relative;
  overflow-x: scroll;

  .icon {
    &.task-icon--finished  svg { fill: $task-finished }
    &.task-icon--next svg { fill: $task-next }
    &.task-icon--active   svg { fill: $task-active}
  }

  &:before {
    position: absolute;
    left: 20px;
    top: 5px;
    width: 4px;

    content: '';
    height: calc(100% + 50px);
    background: rgb(138, 145, 150);
    background: -moz-linear-gradient(left, rgba(138, 145, 150, 1) 0%, rgba(122, 130, 136, 1) 60%, rgba(98, 105, 109, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(138, 145, 150, 1) 0%, rgba(122, 130, 136, 1) 60%, rgba(98, 105, 109, 1) 100%);
    background: linear-gradient(to right, rgba(138, 145, 150, 1) 0%, rgba(122, 130, 136, 1) 60%, rgba(98, 105, 109, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8a9196', endColorstr='#62696d', GradientType=1);

    border-radius: 4px;
  }

  .timeline-section {
    position: relative;
    padding-left: 35px;
    margin-bottom: 30px;

    &:before {
      position: absolute;
      left: 20px;
      top: 5px;
      width: 4px;
      content: '';
      height: calc(100% + 50px);
      background: #8a9196;
      background: linear-gradient(to right, #8a9196 0%, #7a8288 60%, #62696d 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8a9196', endColorstr='#62696d', GradientType=1);
      border-radius: 4px;
    }

    .timeline-row {
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: $base-margin;
      padding-left: $base-padding;

      .timeline-box {
        width: calc(50% - 24px);
        margin: $base-padding;
        border-radius: 0px;

      }
    }
  }
}


header.timeline-month {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: -1px;
  z-index: 100;

  border-radius: 0;
  border: 1px solid #17191B;
  background-color: $app-bg-color;

  margin-bottom: 30px;


  .tm1 {
    flex-direction: row;
    align-items: center;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding-left: 38px;
    background-color: #444950;
    border-right-color: black;
    height: 100%;

    div {
      width: 24px;
      height: 24px;
      margin-left: -29px;

      background: rgb(138, 145, 150);
      background: -moz-linear-gradient(top, rgba(138, 145, 150, 1) 0%, rgba(122, 130, 136, 1) 60%, rgba(112, 120, 125, 1) 100%);
      background: -webkit-linear-gradient(top, rgba(138, 145, 150, 1) 0%, rgba(122, 130, 136, 1) 60%, rgba(112, 120, 125, 1) 100%);
      background: linear-gradient(to bottom, rgba(138, 145, 150, 1) 0%, rgba(122, 130, 136, 1) 60%, rgba(112, 120, 125, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8a9196', endColorstr='#70787d', GradientType=0);
      border-radius: 100%;
      border: 1px solid #17191B;
    }
  }


  nav {
    display: flex;
    flex-direction: row;
    background-color: #444950;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;


    div:nth-child(1) {

    }

    div:nth-child(2) {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      background-color: #111;
    }

    div:nth-child(3) {
      position: absolute;
      top: -1px;
      right: 10px;
      z-index: -1;

      flex-direction: row;
      align-items: center;

      white-space: nowrap;
      padding: 4px 10px 4px 20px;
      box-sizing: border-box;

      opacity: .5;

      span {
        padding-left: 12px;
      }

    }

    div {
      padding: 4px 15px 4px 35px;
    }


  }


}


.timeline-date {
  flex-direction: row;
  align-items: center;
  margin-left: -21px;
  position: sticky;
  top: 30px;
  z-index: 90;

  > div:nth-child(1) {
    width: 16px;
    height: 16px;
    background: linear-gradient(to bottom, #8a9196 0%, #7a8288 60%, #70787d 100%);
    top: 8px;
    left: 8px;
    border: 1px solid #17191B;
    border-radius: 100%;
  }

  > div:nth-child(2) {
    width: 10px;
    height: 2px;
    background-color: #444950;
    top: 15px;
    left: 20px;
  }

  .timeline-date--time {
    font-size: 1.3rem;
    flex: 100;
    padding: 2px 15px;
    background: #607d8b;
    border-radius: 20px;
    border: 1px solid #17191B;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

    &.finished, &.next {
      background: #242527;
    }

  }


}


.timeline-box {
  position: relative;

  background-color: rgba(68, 73, 80, .3);
  border-radius: 15px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid #17191B;
  transition: all 0.3s ease;
  overflow: hidden;
}


.box-title {
  padding: 5px 15px;
  border-bottom: 1px solid #17191B;
  flex-direction: row;
  align-items: center;

  .icon {
    margin: 0 12px 0 0;
    svg {
      width: 12px;
      height: 12px;
    }
  }

  .startTime {
    margin-left: auto;
  }

}


.box-content {
  padding: 5px 15px;
}

.box-content strong {
  color: #666;
  font-style: italic;
  margin-right: 5px;
}

.box-item {
  margin-bottom: 5px;
  flex-direction: row;
}

.box-footer {
  padding: 5px 15px;
  border-top: 1px solid #17191B;
  background-color: rgba(250, 250, 250, .0);
  text-align: right;
  font-style: italic;
}
