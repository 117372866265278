$dark-100: rgba(33, 33, 33, 1);

$app-bg-color: #161616;

$text-gray-50: #fafafa;
$text-gray-100: #f5f5f5;
$text-gray-200: #eeeeee;
$text-gray-300: #e0e0e0;
$text-gray-400: #bdbdbd;
$text-gray-500: #9e9e9e;


$task-finished: #00dd22;
$task-next: #ff9800;
$task-active: #607d8b;